<template>
  <!-- 个人中心 -->
  <div class="account-center">
    <!-- 返回菜单 -->
    <div class="back-home width-200">
      <h3 @click="back">
        <span>
          <i class="iconfont fz-24 black">&#xe647;</i>
        </span>
        <span class="back-text">首页</span>
      </h3>
    </div>

    <!-- 账户信息 -->
    <div class="account" @keyup.enter="handleSaveName">
      <div class="greeting-section">
        <!-- 修改用户名 -->
        <div>
          <h1>
            嗨！
            <span v-if="!editingName" @dblclick="handleEditName">{{ currentUser.username }}</span>
            <input
              class="name-input"
              v-if="editingName"
              v-model="currentUser.username"
              minlength="1"
              maxlength="20"
              @blur="handleSaveName"
              ref="handleUpdateName"
            />
          </h1>
        </div>
        <!-- 编辑姓名按钮 -->
        <div>
          <el-button type="text" v-if="!editingName" @click.stop="handleEditName">
            <i class="iconfont edit-name-btn">&#xe677;</i>
          </el-button>
        </div>
        <div class="account-detail">
          <div class="profile-img">
            <img src="../../../assets/images/headPortrait.jpg" alt />
          </div>
          <div class="account-text">
            <h2>{{ currentUser.email }}</h2>

            <h4>{{ role }}</h4>
            <br />
            <!-- 修改密码弹出框 -->
            <div @blur="cancelPassForm('passwordForm')">
              <button class="pass-btn" @click="passwordPopUp">修改密码</button>

              <el-dialog
                top="12%"
                :close-on-click-modal="false"
                :visible.sync="passDialogVisible"
                :modal-append-to-body="false"
                width="458px"
              >
                <div class="pd-l-20">
                  <span slot="title">
                    <h2>修改您的密码</h2>
                  </span>
                </div>

                <!-- 修改密码表单 -->
                <el-form
                  :model="passwordForm"
                  status-icon
                  :rules="rules"
                  class="pd-20 pd-b-0"
                  ref="passwordForm"
                  :validate-on-rule-change="false"
                >
                  <el-form-item prop="oldPass">
                    <el-input
                      type="password"
                      v-model="passwordForm.oldPass"
                      placeholder="输入旧密码"
                      autocomplete="off"
                    ></el-input>
                  </el-form-item>

                  <el-form-item prop="newPass">
                    <el-input
                      type="password"
                      v-model="passwordForm.newPass"
                      autocomplete="off"
                      placeholder="输入新密码"
                    ></el-input>
                  </el-form-item>

                  <el-form-item prop="checkPass">
                    <el-input
                      type="password"
                      v-model="passwordForm.checkPass"
                      autocomplete="off"
                      placeholder="确认新密码"
                    ></el-input>
                  </el-form-item>

                  <el-form-item>
                    <el-button
                      class="confirm-btn"
                      type="primary"
                      @click="submitNewPass('passwordForm')"
                    >
                      <h3>OK</h3>
                    </el-button>
                  </el-form-item>
                </el-form>
              </el-dialog>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 权限信息表 -->
    <div class="permission">
      <div class="title-section">
        <div>
          <h2>权限明细</h2>
        </div>
        <div class="permission-notice">
          <p>如需申请权限请联系平台管理员</p>
        </div>
      </div>

      <div class="table">
        <el-table
          :data="permissionData"
          style="width: 100%"
          height="400"
          class="data-table"
          header-cell-class-name="table-head"
          cell-class-name="table-cell"
        >
          <el-table-column prop="menu" label="菜单" width="180"></el-table-column>
          <el-table-column prop="page" label="页面" width="180"></el-table-column>
          <el-table-column prop="resource" label="功能" width="180"></el-table-column>
          <el-table-column prop="status" label="权限状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status">支持</span>
              <span v-else>不支持</span>
            </template>
          </el-table-column>
          <el-table-column prop="comment" label="权限说明"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import userApi from "../../../api/user";
import accountApi from "../../../api/account";
import customer from "../../../mixins/customer";
import inputCheck from "../../../uitls/inputCheck";
import formValidations from "@/mixins/form-validations";

export default {
  mixins: [customer, formValidations],
  data() {
    // 密码校验
    // const passwordreg = /^(?=.*[A-Za-z\d])[A-Za-z\d!@#$]{0,20}$/;
    const passwordreg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z\S]{0,20}$/;
    var validateOldPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (!passwordreg.test(value)) {
        callback(
          new Error("密码必须包含数字，字母组合且长度不能超过二十个字符")
        );
      }
      callback();
    };

    var validateNewPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (!passwordreg.test(value)) {
        callback(
          new Error("密码必须包含数字，字母组合且长度不能超过二十个字符")
        );
      } else if (value === this.passwordForm.oldPass) {
        callback(new Error("新旧密码不能相同"));
      } else {
        if (this.passwordForm.checkPass !== "") {
          this.$refs.passwordForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validateCheckPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入新密码"));
      } else if (value !== this.passwordForm.newPass) {
        callback(new Error("两次输入密码不一致!"));
      }
      callback();
    };
    return {
      currentUser: {
        username: "",
        userId: "",
        email: "",
      },
      role: "",
      passDialogVisible: false,
      editingName: false, // 编辑username
      permissionData: [],
      passwordForm: {
        newPass: "",
        checkPass: "",
        oldPass: "",
      },
      rules: {
        oldPass: [
          {
            validator: validateOldPass,
            trigger: "blur",
          },
        ],
        newPass: [
          {
            validator: validateNewPass,
            trigger: "blur",
          },
        ],
        checkPass: [
          {
            validator: validateCheckPass,
            trigger: "blur",
          },
        ],
      },
      errorMsg: "",
      picFocusStatus: false,
    };
  },

  methods: {
    // 初始化
    init() {
      // current user info
      this.currentUser = {
        username: localStorage.getItem("username"),
        userId: localStorage.getItem("uid"),
        email: localStorage.getItem("email"),
      };
      // role info
      userApi.adminUserInfos().then((res) => {
        if (res.data.code === 10200) {
          this.role = res.data.data.role.role_name;
        }
      });

      // permission data
      accountApi.getAccountInfo().then((res) => {
        if (res.data.code === 10200) {
          this.permissionData = res.data.data.resources;
        }
      });
    },
    // back to home page
    back() {
      this.$router.push({
        path: "/home",
      });
    },
    // 加密
    passEncrypt(password) {
      const jsEncrypt = new this.$jsEncrypt();
      jsEncrypt.setPublicKey(this.pubKey); // 加入rsa public key
      return jsEncrypt.encrypt(password);
    },
    // 密码修改
    passwordPopUp() {
      this.passDialogVisible = true;
      this.$nextTick(() => {
        this.$refs["passwordForm"].resetFields();
      });
    },

    submitNewPass(formName) {
      this.errorMsg = "";
      const oldPass = this.passEncrypt(this.passwordForm.oldPass);
      const checkPass = this.passEncrypt(this.passwordForm.checkPass);

      const resetInfo = {
        password: oldPass,
        new_password: checkPass,
      };
      this.validateForm(formName, () => {
        accountApi.adminPasswordChange(resetInfo).then((res) => {
          if (res.data.code === 10200) {
            this.passDialogVisible = false;
            this.$$success("修改成功");
          }
          if (res.data.code === 10001) {
            this.$$error(res.data.message);
          }
          if (res.data.code === 10100) {
            this.$$error(res.data.message);
          }
        });
      });
    },

    cancelPassForm() {
      this.passDialogVisible = false;
      this.$nextTick(() => {
        this.$refs["passwordForm"].resetFields();
      });
    },

    // 用户名更改
    handleEditName() {
      this.editingName = true;
      this.errorMsg = "";
      this.picFocusStatus = true;
      this.$nextTick(() => {
        this.$refs.handleUpdateName.focus();
      });
    },
    handleSaveName() {
      this.errorMsg = "";
      const spRes = inputCheck.checkIsSpace(this.currentUser.username);
      const emjRes = inputCheck.checkhasEmoji(this.currentUser.username);
      if (spRes !== -1) {
        this.errorMsg = inputCheck.checkFormErrorMsg(spRes);
      } else if (emjRes !== -1) {
        this.errorMsg = inputCheck.checkFormErrorMsg(emjRes);
      } else {
        this.editingName = false;
        const usernameInfo = {
          username: this.currentUser.username,
        };
        accountApi.adminAccountUpdate(usernameInfo).then((res) => {
          if (res.data.code === 10200) {
            this.currentUser.username = res.data.data.username;
            localStorage.setItem("username", res.data.data.username);
            this.picFocusStatus = false;
            this.userUpdate(res.data.data);
          } else {
            this.errorMsg = res.data.message;
          }
        });
      }
    },
  },

  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
// general
body {
  font-family: Arial, sans-serif;
}

p {
  font-size: 14px;
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
  color: black;
}

h1 {
  font-size: 48px;
  line-height: 70px;
}

h2 {
  font-size: 24px;
  line-height: 35px;
}

h3 {
  font-size: 18px;
  line-height: 26px;
}

h4 {
  font-size: 14px;
  line-height: 20px;
}

// account section
.account-center {
  padding-left: 114px;
  padding-right: 114px;
  padding-bottom: 93px;
}
.back-home {
  padding-top: 60px;
  vertical-align: center;
  cursor: pointer;
  .arrow {
    margin-right: 44px;
  }
}

.greeting-section {
  display: flex;
}

.edit-name-btn {
  padding: 5px;
}

.account {
  padding-top: 54px;
}

.account-text {
  height: 134px;
  margin-left: 30px;
  display: inline-block;
}

.account-detail {
  padding-top: 37px;
  display: flex;
  align-items: center;
}

.profile-img {
  width: 140px;
  height: 140px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }
}

.profile {
  color: black;
  font-size: 80px;
}

.pass-btn {
  width: 120px;
  height: 40px;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  font-size: 12px;
}

.el-dialog {
  border-radius: 4px;
}

.el-button--primary {
  background-color: white;
  border: 1px solid #7d7d7d;
  color: black;
  width: 100%;
  height: 50px;
}

.edit-name-btn {
  font-size: 16px;
  color: black;
  margin-left: 10px;
}

.name-input,
.name-input:focus {
  font-size: 48px;
  line-height: 70px;
  border: 1px solid #0092fc;
  outline: none;
}

// permission seciton
.permission {
  padding-top: 30px;
  // padding-left: 114px;
  // padding-right: 114px;
}

.permission-notice {
  margin-left: 26px;
  margin-bottom: 10px;
  line-height: 48px;
  text-align: center;
}

.title-section {
  display: flex;
}

.bottom-style {
  margin-top: 20px;
}
</style>
