import axios from '../uitls/require'

export default {
    // 获取admin账户信息（权限信息集合）
    getAccountInfo(){
        return axios.get('/admin/account')
    },
    // admin账户更新
    adminAccountUpdate(usernameInfo){
        return axios.post('/zh-CN/admin/account/update',usernameInfo)
    },
    // admin密码修改
    adminPasswordChange(resetInfo){
        return axios.post('/zh-CN/admin/account/reset-password', resetInfo)
    }

}